<template>
  <div class="container">
    <el-form :inline="true" class="form">
      <el-form-item label="公司:">
        <el-select v-model="company" placeholder="请选择" clearable>
          <el-option
            v-for="item in companyList"
            :key="item.id"
            :value="item.id"
            :label="item.companyName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系人:">
        <el-select v-model="contacts" placeholder="请选择" clearable>
          <el-option
            v-for="item in contactsList"
            :key="item.id"
            :value="item.id"
            :label="item.contactsName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search"></el-button>
      </el-form-item>
      <el-form-item style="position: absolute;right:0">
        <el-button class="el-icon-document-add" type="primary" @click="handleAdd">添加资料</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
        :height="elementHeight"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="company" label="供应商名称"></el-table-column>
        <el-table-column prop="address" label="产品名称"></el-table-column>
        <el-table-column prop="type" label="产品编号"></el-table-column>
        <el-table-column prop="contacts" label="项目类型"></el-table-column>
        <el-table-column prop="contactInformation" label="产品工序"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      ></el-pagination>
    </div>
    <!-- 新增资料弹框 -->
    <el-dialog title="新增资料" :visible.sync="dialogVisible" width="478px">
      <el-form :model="form" label-width="auto" class="addForm">
        <el-form-item label="供应商">
          <el-select v-model="form.company" placeholder="请选择" clearable style="width:100%">
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :value="item.id"
              :label="item.companyName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品名称">
          <el-input v-model="form.address" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="产品编号">
          <el-input v-model="form.address" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="项目类型">
          <el-select v-model="form.type" placeholder="请选择" clearable style="width:100%">
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :value="item.id"
              :label="item.companyName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button
          type="text"
          icon="el-icon-plus"
          style="padding:0 !important"
          @click="handelAddProcess"
        >添加工序</el-button>
        <el-form-item :label="'工序' + (index+1)" v-for="(item,index) in processList" :key="index">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
          <i class="el-icon-close" @click="handleDeleteProcess"></i>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: "",
      contacts: "",
      companyList: [],
      contactsList: [],
      elementHeight: 0,
      tableData: [
        {
          company: "公司名称",
          record: "查看(10)",
        },
        {
          company: "公司名称",
        },
        {
          company: "公司名称",
        },
        {
          company: "公司名称",
        },
        {
          company: "公司名称",
        },
        {
          company: "公司名称",
        },
      ],
      multipleSelection: [],
      // 分页
      start: 0,
      end: 10,
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false,
      form: {},
      processList: [],
    };
  },
  mounted() {
    this.getElementHeight();
    this.companyList = this.$store.state.companyUserList;
    let aside = document.querySelector(".aside");
    aside.style.display = "block";
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 30 + 56 + 48);
      });
    },
    tableBackground(cell) {
      document.getElementsByClassName(cell.column.id)[0].style.background =
        "#E8EBF4";
    },
    // 新增供应商
    handleAdd() {
      this.dialogVisible = true;
    },
    // 添加工序
    handelAddProcess() {
      this.processList.push(1);
    },
    // 删除工序
    handleDeleteProcess() {
      this.processList.pop();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.start = (this.currentPage - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  .el-form-item {
    margin-bottom: 16px !important;
  }
  ::v-deep .el-form-item__label {
    font-weight: bold;
    color: #3d3d3d;
    padding-right: 6px;
  }
}
._Pagination {
  text-align: end;
  padding: 10px 20px;
  background-color: #ffffff;
}
.addForm {
  .el-form-item {
    margin-bottom: 10px !important;
    // margin-right: 24px;
  }
  ::v-deep .el-input__inner {
    height: 40px !important;
  }
  ::v-deep .el-input {
    width: calc(100% - 22px);
  }
  ::v-deep .el-icon-close {
    margin-left: 8px;
  }
}
.table {
  ::v-deep .el-table__row td {
    border-bottom: 1px solid #e8e9ebdb !important;
  }
}
</style>